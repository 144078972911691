import React, { useRef, useEffect, useState } from "react"
import Sidebar from "./sidebar"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { navigate } from "@reach/router"

const InfromationWrapper = styled.div`
  ${({ anchorIcon }) =>
    anchorIcon &&
    `
        .wp-block-file {
    a {
      display: block;
      margin-bottom: 20px;
      text-decoration: underline !important;
      color: black;
          @media (max-width: 768px) {
             margin-bottom: 10px;
          }
      &:before {
        position: relative;
        top: 7px;
        display: inline-block;
        background-size: 28px 30px;
        margin-right: 15px;
        background-image: url("/file-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
        content:"";
        height: 30px;
        width: 30px;
          @media (max-width: 768px) {
            margin-right: 0px;
            background-size: 20px 20px;
          }
      }
    }
  }
  `}

  ${props => props.theme.mobile} {
    p {
      text-align: justify;
    }
    ul {
      margin-left: 20px;
    }
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .wp-block-table {
    overflow-x: scroll;
  }

  table {
    background: ${props => props.theme.colors.tableBackground};
    border: 2px solid ${props => props.theme.colors.tableBorder};
    border-collapse: collapse;
    margin-bottom: 20px;

    min-width: 400px;
  }

  td {
    text-align: center;
    padding: 10px;
  }

  th,
  td {
    border: 1px solid ${props => props.theme.colors.tableBorder};
  }
`

export default function Article({
  wordPressArticle,
  leftColumn,
  additionalContent,
  contentWrapperStyles,
  anchorIcon,
}) {
  const informationData = wordPressArticle
  const mapOfHeadings = useRef({})
  const [content, setContent] = useState(null)
  const [links, setLinks] = useState(null)

  useEffect(() => {
    const location = decodeURIComponent(window.location.hash.split("#")[1])
    if (location && mapOfHeadings.current.hasOwnProperty(location)) {
      handleScroll(location)
    }
  })

  useEffect(() => {
    const linksRender = list => {
      return list.map(element => {
        return (
          <li key={element.heading.innerHTML}>
            <a onClick={() => handleShow(element.heading.innerHTML)}>
              {element.heading.innerHTML}
            </a>
          </li>
        )
      })
    }

    const list = parseHtmlToList({
      html: informationData.content,
      tagName: "H2",
    })
    setContent(contentRender(list))
    setLinks(linksRender(list))
  }, [informationData.content])

  const handleScroll = heading => {
    mapOfHeadings.current[heading].scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  const handleShow = heading => {
    navigate(`#${encodeURIComponent(heading)}`, { replace: true })
  }

  const contentRender = list => {
    return list.map(element => (
      <div key={element.heading.innerHTML}>
        <h2
          ref={heading =>
            (mapOfHeadings.current[element.heading.innerHTML] = heading)
          }
          dangerouslySetInnerHTML={{ __html: element.heading.innerHTML }}
        />
        {element.content.map((content, idx) => {
          const Tag = `${content.nodeName}`.toLowerCase()
          return (
            <Tag
              className={content.className}
              key={`${element.heading.innerHTML}_${idx}`}
              dangerouslySetInnerHTML={{ __html: content.innerHTML }}
            />
          )
        })}
      </div>
    ))
  }

  const parseHtmlToList = ({ html, tagName }) => {
    const div = document.createElement("div")
    div.innerHTML = html
    const htmlList = Array.prototype.slice.call(
      div.getElementsByTagName(tagName)
    )
    const htmlContentList = htmlList.map(tag => {
      let nextSubling = tag.nextSibling
      let contentArray = []

      while (nextSubling.nodeName !== tagName) {
        if (nextSubling.nodeName !== "#text") contentArray.push(nextSubling)
        if (!nextSubling.nextSibling) break
        nextSubling = nextSubling.nextSibling
      }

      return {
        heading: tag,
        content: contentArray,
      }
    })
    return htmlContentList
  }

  return (
    <Sidebar
      heading={informationData.title}
      sticky
      leftColumn={
        <div>
          {leftColumn && leftColumn}
          <ul>{links}</ul>
        </div>
      }
    >
      <InfromationWrapper
        anchorIcon={anchorIcon}
        css={css`
          ${contentWrapperStyles}
        `}
      >
        {additionalContent ? additionalContent : null}
        {content ? (
          content
        ) : (
          <div dangerouslySetInnerHTML={{ __html: informationData.content }} />
        )}
      </InfromationWrapper>
    </Sidebar>
  )
}
