import React from "react"
import Article from "../../components/layout/articleRenderer"
import useStrategicPlan from "../../hooks/use-strategic-plan"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

const imageStyles = css`
  margin-right: 16px;
  max-height: 306px;
`

const WrapperStyle = css`
  .wp-block-buttons {
    width: 100%;
    height: 100%;
    background: gray;
  }
  .wp-block-button {
    width: 100%;
    height: 100%;
    background: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: black;
      font-size: 1.6rem;
      text-align: center;
    }
  }

  .wp-block-columns {
    display: flex;
    max-width: 100%;
    margin: 0 -10px 10px -10px;
    @media (max-width: 768px) {
      margin: 0 -3px 3px -3px;
    }
  }
  .wp-block-column {
    position: relative;
    flex-basis: 20%;
    margin: 0 10px;
    h5 {
      @media (max-width: 768px) {
        font-size: 5px;
      }
    }

    .wp-block-column + .wp-block-column {
      flex-basis: 100%;
    }
    @media (max-width: 768px) {
      margin: 0 3px;
    }
    text-align: center;
    h5 {
      display: block;
      padding: 10px;
    }
    .wp-block-file {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 90;
      font-size: 1.8rem;
      a {
        display: block;
        width: 100%;
        height: 100%;
        color: transparent;
        text-align: right;
      }
    }
  }
  .wp-block-image {
    img {
      width: 100%;
      height: auto;
    }
  }
`

const LeftColumn = () => {
  return (
    <div>
      <StaticImage
        src="../../images/master-plan/cover.png"
        alt="Обложка книги мастер плана города Перми"
        layout="constrained"
        placeholder="tracedSVG"
        css={css`
          ${imageStyles}
        `}
      />
    </div>
  )
}

export default function StrategicPlan() {
  const genPlanData = useStrategicPlan()

  return (
    <Article
      wordPressArticle={genPlanData}
      leftColumn={<LeftColumn />}
      contentWrapperStyles={WrapperStyle}
    />
  )
}
